<template>
  <div>
    <commissions tab="adm"> </commissions>
  </div>
</template>

<script>
import Commissions from "@/views/commons/components/commissions/CommissionsComponent.vue";
export default {
  name: "Commissions",
  data() {
    return {};
  },
  components: {
    commissions: Commissions,
  },
};
</script>